import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import pb from '../pocketbase';
import axios from 'axios'; // Pentru obținerea adresei IP
import './App.css';

const totalsRecordId = "l9be51emzt683st"; // ID-ul recordului din colecția `totals` pentru actualizări

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [message, setMessage] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [deviceInfo, setDeviceInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIpAddress(response.data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    setDeviceInfo(navigator.userAgent);
  }, []);

  const incrementRegisteredUsers = async () => {
    try {
      const totalsRecord = await pb.collection('totals').getOne(totalsRecordId);
      const currentCount = totalsRecord.registeredUsers || 0;
      
      await pb.collection('totals').update(totalsRecordId, {
        registeredUsers: currentCount + 1,
      });
      console.log('Registered users count incremented:', currentCount + 1);
    } catch (error) {
      console.error('Error incrementing registered users count:', error);
    }
  };

  const handleRegister = async () => {
    if (!username || !password || !confirmPassword) {
      setMessage('Please fill in all fields.');
      return;
    }

    if (username.length < 3) {
      setMessage('Username must be at least 3 characters long.');
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    if (!isConfirmed) {
      setMessage('Please confirm the checkbox to proceed with registration.');
      return;
    }

    setLoading(true);

    try {
      const existingUsers = await pb.collection('users').getFullList({
        filter: `ipAddress="${ipAddress}" || deviceInfo="${deviceInfo}"`,
      });

      if (existingUsers.length >= 2) {
        setMessage(
          'You have exceeded the maximum limit of 2 registrations. For more information or assistance, you can contact us <a href="https://starlessx.com/contact" target="_blank">here</a>.'
        );
        setLoading(false);
        return;
      }

      let inviterUsername = null; // Numele persoanei care a invitat
      let inviterId = null; // ID-ul persoanei care a invitat
      const referral = username.toUpperCase();

      if (referralCode) {
        try {
          const inviter = await pb.collection('users').getFirstListItem(`referral="${referralCode.toUpperCase()}"`);
          if (!inviter) {
            setMessage('Invalid referral code. Please try again or continue without referral.');
            setLoading(false);
            return;
          }
          inviterUsername = inviter.username;
          inviterId = inviter.id; // Păstrăm ID-ul pentru a-l folosi mai târziu
        } catch (error) {
          setMessage('Invalid referral code. Please try again or continue without referral.');
          setLoading(false);
          return;
        }
      }

      const newUser = await pb.collection('users').create({
        username,
        password,
        passwordConfirm: confirmPassword,
        sgxBalance: 0,
        starBalance: 0,
        tapEarnRate: 1,
        energyRegenRate: 2,
        containers: 0,
        energy: 0,
        rankIndex: 0,
        xp: 0,
        invitedUsers: 0,
        ipAddress,
        deviceInfo,
        waitingTasks: [],
        canCollectTasks: [],
        completedTasks: [],
        adCooldownTasks: [],
        usedCodes: [],
        referral,
        inviterId: inviterId|| null, // Stocăm numele persoanei care a invitat
        referralStarBalance: 0, // Inițializăm balanța de referral la 0

      });

      console.log('New user created:', newUser);

      await incrementRegisteredUsers();

      await pb.collection('waitings').create({
        userId: newUser.id,
        username: newUser.username,
        referral: newUser.referral,
        waitingContainersUpdate: 0,
        waitingInvitedUsersUpdate: 0,
      });

      if (referralCode) {
        try {
          const inviterRecord = await pb.collection('waitings').getFirstListItem(`userId="${inviterId}"`);

          if (inviterRecord) {
            await pb.collection('waitings').update(inviterRecord.id, {
              waitingContainersUpdate: inviterRecord.waitingContainersUpdate + 1,
              waitingInvitedUsersUpdate: inviterRecord.waitingInvitedUsersUpdate + 1,
            });
          } else {
            await pb.collection('waitings').create({
              userId: inviterId,
              username: inviterUsername,
              referral: referralCode.toUpperCase(),
              waitingContainersUpdate: 1,
              waitingInvitedUsersUpdate: 1,
            });
          }

          const newUserWaitingRecord = await pb.collection('waitings').getFirstListItem(`userId="${newUser.id}"`);
          await pb.collection('waitings').update(newUserWaitingRecord.id, {
            waitingContainersUpdate: 1,
          });

          setMessage(`You have registered successfully using a referral code from ${inviterUsername}!`);
        } catch (error) {
          console.error('Error updating inviter record:', error);
          setMessage('An error occurred while processing the referral code.');
        }
      } else {
        setMessage('Registration successful! You can now log in.');
      }

      setTimeout(() => {
        navigate('/login');
      }, 4000);

    } catch (error) {
      console.error('Error during registration:', error);

      if (error.response && error.response.data) {
        const errorData = error.response.data;
        if (errorData.username && errorData.username.message) {
          setMessage(`${errorData.username.message}`);
        } else {
          setMessage('Failed to create record.');
        }
      } else if (error.status === 0 || error.response === undefined) {
        setMessage('There are issues on our side. Please try again later.');
      } 

      if (error.response && error.response.data) {
        const errorData = error.response.data;
        if (errorData.email && errorData.email.message) {
          setMessage(`${errorData.email.message}`);
        } 
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-container">
      <div className="register-card">
        <div className="register-image-container">
          <img 
            src="https://starlessx.com/assets/img/images/login-img.png" 
            alt="Starless Games" 
            className="register-image"
          />
        </div>
        <div className="register-body">
          <div className="register-header">
            <i className="register-icon fas fa-cubes fa-3x"></i>
            <span className="register-logo">Welcome</span>
          </div>
          <h5 className="register-title">Create a new account</h5>
          <input 
            className="register-input"
            type="text"
            placeholder="Username"
            maxLength={20}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input 
            className="register-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input 
            className="register-input"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <input 
            className="register-input"
            type="text"
            placeholder="Enter the referral code"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
          <div className="register-check">
            <label htmlFor="confirmCheck">
              <input
                type="checkbox"
                id="confirmCheck"
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
              />
              By ticking this box, you confirm that if you forget your login credentials or password, account recovery will not be possible, and StarlessX will not be held responsible for any lost data.
            </label>
          </div>
          <button 
            className="register-button"
            onClick={handleRegister}
            style={{
              backgroundColor: loading ? '#fff' : '', 
              color: loading ? '#000' : '', 
              pointerEvents: loading ? 'none' : 'auto' 
            }}
          >
            {loading ? 'Registering...' : 'Register'}
          </button>

          <p className="register-message" dangerouslySetInnerHTML={{ __html: message }}></p>
          <p className="register-login">
            Already have an account? <a className="register-login-link" href="/login">Login here</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;

// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SGXProvider } from '../context/SGXContext';
import { UserProvider, useUserContext } from '../context/UserContext'; // Importăm UserContext

import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
import Home from './Home';
import Library from './Library';
import Store from './Store';
import Wallet from './Wallet';
import Friends from './Friends';
import TapToEarn from './TapToEarn';
import SendModal from './SendModal';

import './App.css';

// PrivateRoute component to protect routes
const PrivateRoute = ({ element, redirectTo }) => {
  const { isAuthenticated } = useUserContext(); // Folosim contextul de autentificare

  // Dacă utilizatorul nu este autentificat, redirecționăm la pagina specificată
  return isAuthenticated ? element : <Navigate to={redirectTo} />;
};

// PublicRoute component for public routes that redirects authenticated users
const PublicRoute = ({ element, redirectTo }) => {
  const { isAuthenticated } = useUserContext(); // Folosim contextul de autentificare

  // Dacă utilizatorul este autentificat, redirecționăm la pagina specificată (ex: /home)
  return isAuthenticated ? <Navigate to={redirectTo} /> : element;
};

function App() {
  const [showSendModal, setShowSendModal] = React.useState(false);

  return (
    <SGXProvider>
      <UserProvider> {/* Asigurăm înfășurarea aplicației cu UserProvider */}
        <Router>
          <Routes>
            {/* Default route - verificăm dacă utilizatorul este autentificat */}
            <Route 
              path="/" 
              element={<PublicRoute element={<Navigate to="/register" />} redirectTo="/home" />} 
            />

            {/* Public routes */}
            <Route path="/login" element={<PublicRoute element={<Login />} redirectTo="/home" />} />
            <Route path="/register" element={<PublicRoute element={<Register />} redirectTo="/home" />} />
            <Route path="/reset-password" element={<PublicRoute element={<ResetPassword />} redirectTo="/home" />} />

            {/* Private routes */}
            <Route path="/home" element={<PrivateRoute element={<Home />} redirectTo="/register" />} />
            <Route path="/library" element={<PrivateRoute element={<Library />} redirectTo="/register" />} />
            <Route path="/store" element={<PrivateRoute element={<Store />} redirectTo="/register" />} />
            <Route path="/wallet" element={<PrivateRoute element={<Wallet onSendClick={() => setShowSendModal(true)} />} redirectTo="/register" />} />
            <Route path="/friends" element={<PrivateRoute element={<Friends />} redirectTo="/register" />} />
            <Route path="/tap-to-earn" element={<PrivateRoute element={<TapToEarn />} redirectTo="/register" />} />
          </Routes>
        </Router>
      </UserProvider>
    </SGXProvider>
  );
}

export default App;

import React from 'react';
import QRCode from 'qrcode.react';
import './App.css';

const ReceiveModal = ({ onClose, address }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    alert("Address copied to clipboard!");
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Receive</h2>
          <button className="modal-close" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <div className="qr-code">
            <QRCode value={address} size={256} />
          </div>
          <div className="address-container">
            <span>{address}</span>
            <button className="copy-button" onClick={copyToClipboard}>Copy</button>
          </div>
        </div>
        <div className="modal-footer">
          <button className="cancel-button" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ReceiveModal;

import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { FaUserPlus, FaUserCheck, FaGift } from 'react-icons/fa';
import pb from '../pocketbase';

const RefferalsSection = () => {
  const { user, updateStarBalance, updateRocketTicket, updateContainers, addUsedCode } = useContext(UserContext);
  const [promoCode, setPromoCode] = useState('');
  const [referralBalance, setReferralBalance] = useState(0); 
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const [isProcessingClaim, setIsProcessingClaim] = useState(false);
  const [isProcessingPromo, setIsProcessingPromo] = useState(false);

  // Funcția pentru vibrații în funcție de tipul de feedback
  const triggerVibration = (type) => {
    if (!window.navigator.vibrate) return; // verificare suport pentru vibratii
    
    if (type === 'success') {
      window.navigator.vibrate([100, 50, 100]); // dublă vibrație pentru succes
    } else if (type === 'error') {
      window.navigator.vibrate([100, 100, 100, 100, 100]); // triplă vibrație pentru eroare
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const fetchReferralBalance = async () => {
    try {
      const record = await pb.collection('waitings').getFirstListItem(`userId="${user.id}"`);
      setReferralBalance(record.referralStarBalance);
    } catch (error) {
      console.error('Error fetching referral balance:', error);
    }
  };

  useEffect(() => {
    fetchReferralBalance();
  }, [user.id]);

  const handleClaimReferralEarnings = async () => {
    if (referralBalance > 0) {
      setIsProcessingClaim(true);
      try {
        await updateStarBalance(referralBalance);
        setReferralBalance(0); 
        
        const record = await pb.collection('waitings').getFirstListItem(`userId="${user.id}"`);
        await pb.collection('waitings').update(record.id, { referralStarBalance: 0 });

        setMessageType('valid-code-message');
        setMessage('Referral earnings claimed successfully!');
        triggerVibration('success'); // declanșează vibrația pentru succes
      } catch (error) {
        setMessageType('invalid-code-message');
        setMessage('An error occurred while claiming earnings. Please try again.');
        triggerVibration('error'); // declanșează vibrația pentru eroare
        console.error('Error claiming referral earnings:', error);
      } finally {
        setIsProcessingClaim(false);
      }
    } else {
      setMessageType('invalid-code-message');
      setMessage('No referral earnings to claim.');
      triggerVibration('error'); // vibrație de eroare
    }
  };
  
  const handleClaimPromoCode = async () => {
    setMessage('');
    setIsProcessingPromo(true);
    try {
      const normalizedCode = promoCode.toUpperCase();

      if (user.usedCodes && user.usedCodes.includes(normalizedCode)) {
        setMessageType('used-code-message');
        setMessage('This code has already been used.');
        triggerVibration('error'); // vibrație de eroare
        return;
      }

      const rewards = await pb.collection('rewards').getFirstListItem(`promoCode="${normalizedCode}"`);
      if (!rewards) {
        setMessageType('invalid-code-message');
        setMessage('Promo code is invalid or has expired.');
        triggerVibration('error'); // vibrație de eroare
        return;
      }

      const rewardsToApply = { containers: 5, starBalance: 10000, rocketTicket: 5 };
      updateStarBalance(rewardsToApply.starBalance);
      updateRocketTicket(rewardsToApply.rocketTicket);
      updateContainers(rewardsToApply.containers);

      await addUsedCode(normalizedCode);

      setMessageType('valid-code-message');
      setMessage('The code has been activated!');
      triggerVibration('success'); // vibrație de succes
    } catch (error) {
      if (error.status === 404) {
        setMessageType('invalid-code-message');
        setMessage('Promo code is invalid or has expired.');
      } else {
        setMessageType('invalid-code-message');
        setMessage('An error occurred. Please try again.');
      }
      triggerVibration('error'); // vibrație de eroare
      console.error('Error claiming promo code:', error);
    } finally {
      setIsProcessingPromo(false);
    }
  };

  const handleCopyLink = () => {
    const referralLink = `${user.referral}`;
    navigator.clipboard.writeText(referralLink);
    setMessageType('valid-code-message');
    setMessage('Referral code copied!');
    triggerVibration('success'); // vibrație de succes la copiere
  };

  return (
    <div className="game-display">
      <div className="referral-steps-container">
        <h2 className="referral-steps-title">How It Works</h2>
        <div className="referral-steps">
          <div className="step">
            <div className="step-icon">
              <FaUserPlus />
            </div>
            <h3 className="step-title">1. Invite a Friend</h3>
            <p className="step-description">Share your referral code with a friend.</p>
          </div>
          <div className="step">
            <div className="step-icon">
              <FaUserCheck />
            </div>
            <h3 className="step-title">2. Friend Registers</h3>
            <p className="step-description">Your friend signs up on the platform using your referral code.</p>
          </div>
          <div className="step">
            <div className="step-icon">
              <FaGift />
            </div>
            <h3 className="step-title">3. You are Rewarded</h3>
            <p className="step-description">You will receive 5% of his earnings, and each of you will receive a case as a reward.</p>
          </div>
        </div>
      </div>

      <div className='referral-containers'>
        <div className="referral-link-container">
          <h3>Your Referral Code</h3>
          <h5>Invited Users: {`${user.invitedUsers}`}</h5>
          <p>Invite your friends to the app and encourage them to use your referral code to get a free gift.</p>
          <div className="referral-link-box">
            <p className='link-box-p'>{`${user.referral}`}</p>
          </div>
          <hr className='menu-line' />

          <button className='ref-btn' onClick={handleCopyLink}>Copy the Referral Code</button>
        </div>

        <div className="referral-link-container">
          <h3>Your Referral Earnings</h3>
          <p>You will earn 5% of everything collected by those who register with your referral code.</p>
          <div className="referral-link-box">
            <p className='link-box-p'>{`${referralBalance.toLocaleString()} $STAR`}</p>
          </div>
          <hr className='menu-line' />

          <button 
            className='ref-btn' 
            onClick={handleClaimReferralEarnings} 
            disabled={isProcessingClaim}
          >
            {isProcessingClaim ? 'Processing...' : 'Collect Available Balance'}
          </button>
        </div>

        <div className="referral-link-container">
          <h3>Enter the Promo Code</h3>
          <p>Enter your promo code here, which StarlessX offers during special events.</p>
          <div className="referral-link-box">
            <input
              type="text"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder="Enter the promo code here"
              className='code-input'
            />
          </div>
          <hr className='menu-line' />

          <button 
            className='ref-btn' 
            onClick={handleClaimPromoCode} 
            disabled={isProcessingPromo}
          >
            {isProcessingPromo ? 'Processing...' : 'Use the Promo Code'}
          </button>
        </div>

        {message && <p className={`promo-message ${messageType}`}>{message}</p>}
      </div>
    </div>
  );
};

export default RefferalsSection;

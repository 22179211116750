// src/pages/ResetPassword.jsx
import React, { useState } from 'react';
import pb from '../pocketbase'; // Importă clientul PocketBase
import './App.css';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleReset = async () => {
    if (!email) {
      setMessage('Please enter your email address.');
      return;
    }

    try {
      // Creează token-ul de resetare a parolei folosind PocketBase
      await pb.collection('users').requestPasswordReset(email);
      setMessage('Password reset link sent to your email!');
    } catch (error) {
      console.error('Error during registration:', error);

    // Extract specific error details from the response
    if (error.response && error.response.data) {
      const errorData = error.response.data;

      // Check if there are validation errors related to 'username'
      if (errorData.email && errorData.email.message) {
        setMessage(`${errorData.email.message}`);
      } 
    }
  }
    
  };

  return (
    <div className="recovery-container">
      <div className="recovery-card">
        <div className="recovery-image-container">
          <img 
              src="https://starlessx.com/assets/img/images/login-img.png" 
              alt="recovery form" 
              className="recovery-image"
          />
        </div>
        <div className="recovery-body">
          <div className="recovery-header">
            <i className="recovery-icon fas fa-cubes fa-3x"></i>
            <span className="recovery-logo">Welcome</span>
          </div>
          <h5 className="recovery-title">Recover your password</h5>
          <input 
            className="recovery-input"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="recovery-button" onClick={handleReset}>Send Recovery Email</button>
          <p className="recovery-message">{message}</p>
          <p className="recovery-login">
            Remember your password? <a className="recovery-login-link" href="/login">Login here</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

import React, { useState } from 'react';
import sgxImage from './SGX.png';
import egldImage from './EGLD.png';
import './App.css';

const SwapModal = ({ onClose }) => {
  const [sendAmount, setSendAmount] = useState('');
  const [receiveAmount, setReceiveAmount] = useState('');
  const [sendToken, setSendToken] = useState('SGX');
  const [receiveToken, setReceiveToken] = useState('EGLD');

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Swap</h2>
          <button className="modal-close" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <div className="swap-group">
            <input 
              type="number" 
              placeholder="Amount to Swap" 
              value={sendAmount} 
              onChange={(e) => setSendAmount(e.target.value)}
            />
            <select onChange={(e) => setSendToken(e.target.value)} value={sendToken}>
              <option value="SGX">SGX <img src={sgxImage} alt="SGX" className="token-icon" /></option>
              <option value="EGLD">EGLD <img src={egldImage} alt="EGLD" className="token-icon" /></option>
            </select>
          </div>
          <div className="swap-group">
            <input 
              type="text" 
              placeholder="You Receive" 
              value={receiveAmount} 
              readOnly
            />
            <select onChange={(e) => setReceiveToken(e.target.value)} value={receiveToken}>
              <option value="SGX">SGX <img src={sgxImage} alt="SGX" className="token-icon" /></option>
              <option value="EGLD">EGLD <img src={egldImage} alt="EGLD" className="token-icon" /></option>
            </select>
          </div>
        </div>
        <div className="modal-footer">
          <button className="send-button">Swap</button>
          <button className="cancel-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default SwapModal;

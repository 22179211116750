// src/context/UserContext.js
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { SGXContext } from './SGXContext';
import pb from '../pocketbase'; // Importăm clientul PocketBase
import axios from 'axios';


export const UserContext = createContext();
export const useUserContext = () => useContext(UserContext); // Adaugă această linie
const totalsRecordId = "l9be51emzt683st"; // ID-ul recordului din colecția `totals` pentru actualizări
const MIDDLEWARE_URL = 'http://51.68.215.26:3001/updateUserCache';



// Funcția care actualizează balanța totală de Star Balance în colecția `totals`
const updateTotalStarBalance = async (amount) => {
    try {
        const totalsRecord = await pb.collection('totals').getOne(totalsRecordId);
        const updatedTotalStarBalance = totalsRecord.totalStarBalance + amount;

        await pb.collection('totals').update(totalsRecordId, {
            totalStarBalance: updatedTotalStarBalance
        });
        console.log(`Total Star Balance updated: ${updatedTotalStarBalance}`);
    } catch (error) {
        console.error('Error updating Total Star Balance:', error);
    }
};


// Funcția care actualizează balanța totală de SGX Balance în colecția `totals`
const updateTotalSgxBalance = async (amount) => {
    try {
        const totalsRecord = await pb.collection('totals').getOne(totalsRecordId);
        const updatedTotalSgxBalance = totalsRecord.totalSgxBalance + amount;

        await pb.collection('totals').update(totalsRecordId, {
            totalSgxBalance: updatedTotalSgxBalance
        });
        console.log(`Total SGX Balance updated: ${updatedTotalSgxBalance}`);
    } catch (error) {
        console.error('Error updating Total SGX Balance:', error);
    }
};

// Funcția pentru actualizarea referralStarBalance în colecția `waitings` cu 5%
const updateReferralStarBalance = async (inviterId, amount) => {
    try {
        // Căutăm în colecția `waitings` înregistrarea care are `userId` corespunzător `inviterId`
        const inviterRecord = await pb.collection('waitings').getFirstListItem(`userId="${inviterId}"`);

        // Calculăm noul balance de referral
        const newReferralBalance = inviterRecord.referralStarBalance + amount;

        // Actualizăm valoarea referralStarBalance direct în `waitings`
        await pb.collection('waitings').update(inviterRecord.id, { referralStarBalance: newReferralBalance });
        console.log(`Referral Star Balance updated in waitings for inviter with userId ${inviterId}: +${amount}`);
    } catch (error) {
        console.error('Error updating Referral Star Balance in waitings:', error);
    }
};









// Functia pentru setare ca activ
const setActiveInDB = async () => {
    try {
        await pb.collection('totals').update(totalsRecordId, { activeUsers: 1 });
        console.log('User marked as active in DB.');
    } catch (error) {
        console.error('Error marking user as active:', error);
    }
};

// Functia pentru setare ca inactiv
const setInactiveInDB = async () => {
    try {
        await pb.collection('totals').update(totalsRecordId, { activeUsers: 0 });
        console.log('User marked as inactive in DB.');
    } catch (error) {
        console.error('Error marking user as inactive:', error);
    }
};








const ranks = [
    { name: 'Rank 1', requiredTaps: 3000, image: './rank1.png', energyLimit: 500 },
    { name: 'Rank 2', requiredTaps: 10000, image: './rank2.png', energyLimit: 700 },
    { name: 'Rank 3', requiredTaps: 30000, image: './rank3.png', energyLimit: 900 },
    { name: 'Rank 4', requiredTaps: 50000, image: './rank4.png', energyLimit: 1100 },
    { name: 'Rank 5', requiredTaps: 70000, image: './rank5.png', energyLimit: 1300 },
    { name: 'Rank 6', requiredTaps: 90000, image: './rank6.png', energyLimit: 1500 },
    { name: 'Rank 7', requiredTaps: 150000, image: './rank7.png', energyLimit: 1700 },
    { name: 'Rank 8', requiredTaps: 200000, image: './rank8.png', energyLimit: 1800 },
    { name: 'Rank 9', requiredTaps: 250000, image: './rank9.png', energyLimit: 1900 },
    { name: 'Rank 10', requiredTaps: 500000, image: './rank10.png', energyLimit: 2000 },
];
export const UserProvider = ({ children }) => {
    const { generatedSgxBalance } = useContext(SGXContext);
    

    const [user, setUser] = useState(() => {
        // Inițializăm utilizatorul din localStorage dacă există
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : {
            username: '',
            email: '',
            sgxBalance: 0,
            starBalance: 0,
            tapEarnRate: 1,
            energyRegenRate: 2,
            containers: 0,
            energy: 0,
            rankIndex: 0,
            prevRankIndex: 0, 
            xp: 0,
            invitedUsers: 0,
            rocketTicket: 0,
            
        waitingTasks: [], // Inițializare ca listă goală
        canCollectTasks: [], // Inițializare ca listă goală
        completedTasks: [], // Inițializare ca listă goală
        adCooldownTasks: [],
        usedCodes: [],
        referralStarBalance: 0, // <-- Noua balanță de referral
        inviterId: null, // <-- Atribuit la înregistrare, numele celui care a invitat


        };
    });

    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Inițializăm autentificarea din localStorage
        return localStorage.getItem('isAuthenticated') === 'true';
    });
    
    const [isFetching, setIsFetching] = useState(true);
    const userDataRef = useRef(user);
    const [userInactive, setUserInactive] = useState(false);
    const inactivityTimeout = useRef(null);
    const isUserInitiallyActive = useRef(false);



    

    const sendToRedis = async (userId, data) => {
        try {
            await axios.post(MIDDLEWARE_URL, { userId, updateData: data });
            console.log('Data cached in Redis:', data);
        } catch (error) {
            console.error('Error caching data in Redis:', error);
        }
    };

    const handleDataSync = (field, amount) => {
        const updateData = { ...user, [field]: user[field] + amount };
        setUser(updateData);
        userDataRef.current = updateData;
        sendToRedis(user.username, updateData);
    };

    const updateUserField = (field, amount) => {
        setUser((prevUser) => ({ ...prevUser, [field]: prevUser[field] + amount }));
        sendToRedis(user.username, { [field]: user[field] + amount });
    };

    

    // Functie care seteaza utilizatorul ca inactiv dupa 30 secunde de inactivitate
    const startInactivityCheck = () => {
        clearTimeout(inactivityTimeout.current);

        inactivityTimeout.current = setTimeout(() => {
            setUserInactive(true);
            setInactiveInDB();
        }, 30000); // Setam la 30 de secunde de inactivitate
    };

    // Functie pentru resetarea timer-ului la activitate
    const resetInactivityCheck = () => {
        if (userInactive) {
            setActiveInDB();
            setUserInactive(false);
        }
        startInactivityCheck();
    };

    // La accesare componenta, activam utilizatorul o singura data
    useEffect(() => {
        if (!isUserInitiallyActive.current) {
            setActiveInDB(); // Setam utilizatorul activ doar o data
            isUserInitiallyActive.current = true;
        }
        startInactivityCheck();

        return () => {
            clearTimeout(inactivityTimeout.current);
            setInactiveInDB();
        };
    }, []);

    useEffect(() => {
        const handleActivity = () => resetInactivityCheck();
        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('click', handleActivity);
        document.addEventListener('keydown', handleActivity);
        
        return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('click', handleActivity);
            document.removeEventListener('keydown', handleActivity);
        };
    }, [userInactive]);

    











    // Adaugă în UserProvider

    const getUsername = () => {
      return user ? user.username : null; // Verifică dacă utilizatorul există
    };

    const inviteUser = async (inviterUsername, newUserId) => {
      try {
        // Delay of 2 seconds to simulate processing time
        await new Promise((resolve) => setTimeout(resolve, 2000));
    
        // Fetch inviter by username
        const inviter = await pb.collection('users').getFirstListItem(`username="${inviterUsername}"`);
    
        if (!inviter) {
          throw new Error('Inviter not found');
        }
    
        // Update inviter's invited users count and containers
        await pb.collection('users').update(inviter.id, {
          invitedUsers: inviter.invitedUsers + 1,
          containers: inviter.containers + 1,  // Offer a container to the inviter
        });
    
        // Update the new user to give them a container
        await pb.collection('users').update(newUserId, {
          containers: 1,  // Assign a container to the new user
        });
    
        console.log('User updated successfully:', inviter);
      } catch (error) {
        console.error('Error updating invited users:', error);
      }
    };
    
    
    
  
    
    
    


    const fetchUserData = async (userId) => {
        try {
            const userRecord = await pb.collection('users').getOne(userId);
            setUser((prevUser) => ({
                ...prevUser,
                ...userRecord,
            }));
            userDataRef.current = { ...user, ...userRecord };
            setIsFetching(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const saveUserData = async (userId, data) => {
        try {
            await pb.collection('users').update(userId, data);
        } catch (error) {
            console.error('Error saving user data:', error);
        }
    };

    useEffect(() => {
        const currentUser = pb.authStore.model;
        if (currentUser && isFetching) {
            fetchUserData(currentUser.id);
        }
    }, [pb.authStore.model, isFetching]);

    useEffect(() => {
        const currentUser = pb.authStore.model;
        if (currentUser && !isFetching) {
            saveUserData(currentUser.id, user);
            // Salvăm user-ul în localStorage
            localStorage.setItem('user', JSON.stringify(user));
        }
    }, [user, isFetching]);

    useEffect(() => {
        if (!isFetching) {
            setUser((prevUser) => ({
                ...prevUser,
                sgxBalance: prevUser.sgxBalance + generatedSgxBalance,
            }));
        }
    }, [generatedSgxBalance, isFetching]);

    const updateUser = (updates) => {
        setUser((prevUser) => ({
            ...prevUser,
            ...updates,
        }));
        userDataRef.current = { ...user, ...updates };
        // Actualizăm și în localStorage
        localStorage.setItem('user', JSON.stringify({ ...user, ...updates }));
    };

    const resetUser = () => {
        setUser({
            username: '',
            email: '',
            sgxBalance: 0,
            starBalance: 0,
            tapEarnRate: 1,
            energyRegenRate: 2,
            containers: 0,
            energy: 0,
            rankIndex: 0,
            xp: 0,
            invitedUsers: 0,
            rocketTicket: 0,
            
        
        });
        setIsFetching(true);
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('user');
    };

    const loginUser = () => {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
    };

    const regenerateEnergy = () => {
        setUser((prevUser) => {
            const currentRank = ranks[prevUser.rankIndex];
            const energyLimit = currentRank.energyLimit;
            if (prevUser.energy < energyLimit) {
                return { ...prevUser, energy: prevUser.energy + 1 };
            }
            return prevUser;
        });
    };

    const handleTap = () => {
        if (user.energy > 0) {
            handleDataSync('starBalance', user.tapEarnRate);
            handleDataSync('energy', -1);
            const referralBonus = user.tapEarnRate * 0.05;

            if (user.inviterId) {
                handleDataSync('referralStarBalance', referralBonus);
            }
            setUser((prevUser) => {
                let newXP = prevUser.xp + 1;
                let newRankIndex = prevUser.rankIndex;
                let newContainers = prevUser.containers;

                if (newXP >= ranks[prevUser.rankIndex].requiredTaps) {
                    if (prevUser.rankIndex < ranks.length - 1) {
                        newRankIndex = Math.min(prevUser.rankIndex + 1, ranks.length - 1);
                        newContainers += 1;
                        newXP = 0;
                    }
                }

                return {
                    ...prevUser,
                    xp: newXP,
                    rankIndex: newRankIndex,
                    containers: newContainers,
                };
            });
        }
    };

    const updateTasks = async (newCompletedTasks, newCanCollectTasks, newWaitingTasks, newAdCooldownTasks) => {
        try {
            const userRecord = await pb.collection('users').getOne(user.id);

            // Actualizăm task-urile utilizatorului, inclusiv adCooldownTasks
            const updatedUserData = {
                completedTasks: newCompletedTasks || userRecord.completedTasks || [],
                canCollectTasks: newCanCollectTasks || userRecord.canCollectTasks || [],
                waitingTasks: newWaitingTasks || userRecord.waitingTasks || [],
                adCooldownTasks: newAdCooldownTasks || userRecord.adCooldownTasks || []

            };

            // Salvăm datele actualizate ale utilizatorului în PocketBase
            await pb.collection('users').update(user.id, updatedUserData);

            // Actualizăm și starea locală a utilizatorului pentru a reflecta modificările
            setUser((prevUser) => ({
                ...prevUser,
                completedTasks: updatedUserData.completedTasks,
                canCollectTasks: updatedUserData.canCollectTasks,
                waitingTasks: updatedUserData.waitingTasks,
                adCooldownTasks: updatedUserData.adCooldownTasks,

            }));

            console.log("Tasks updated successfully, including adCooldownTasks.");
        } catch (error) {
            console.error("Error updating tasks:", error);
        }
    };


    // Adaugă această funcție în UserContext.js
const addUsedCode = async (code) => {
    try {
        const updatedUsedCodes = user.usedCodes ? [...user.usedCodes, code] : [code];

        // Actualizăm în PocketBase
        await pb.collection('users').update(user.id, { usedCodes: updatedUsedCodes });

        // Actualizăm starea locală a utilizatorului pentru a reflecta schimbările
        setUser((prevUser) => ({
            ...prevUser,
            usedCodes: updatedUsedCodes,
        }));
        console.log(`Promo code "${code}" added to usedCodes.`);
    } catch (error) {
        console.error("Error updating used codes:", error);
    }
};

    

    const updateStarBalance = (amount) => {
    // Folosim un callback pentru setUser pentru a ne asigura că avem cea mai recentă stare
    setUser((prevUser) => {
        const newStarBalance = prevUser.starBalance + amount;
        const referralBonus = amount * 0.05;

        // Actualizăm balanța local a utilizatorului
        const updatedUser = {
            ...prevUser,
            starBalance: newStarBalance,
        };

        // Sincronizăm în baza de date și actualizăm balanța totală
        updateTotalStarBalance(amount).catch(error =>
            console.error('Error updating total star balance:', error)
        );

        // Dacă există un inviter, acordăm bonusul de 5% invitatului
        if (prevUser.inviterId) {
            updateReferralStarBalance(prevUser.inviterId, referralBonus).catch(error =>
                console.error('Error updating referral star balance:', error)
            );
        }

        console.log(`User ${prevUser.username} earned ${amount} Star. New Star Balance: ${newStarBalance}`);
        return updatedUser;
    });
};

    
    const updateRocketTicket = (amount) => {
        setUser((prevUser) => ({
            ...prevUser,
            rocketTicket: prevUser.rocketTicket + amount,
        }));
    };

    const updateSGXBalance = (amount) => {
        setUser((prevUser) => {
            const newSgxBalance = prevUser.sgxBalance + amount;
            updateTotalSgxBalance(amount);  // Actualizează totalul global

            return {
                ...prevUser,
                sgxBalance: newSgxBalance,
            };
        });
    };

    const updateEnergyRegenRate = (amount) => {
        setUser((prevUser) => ({
            ...prevUser,
            energyRegenRate: Math.max(0.1, prevUser.energyRegenRate - amount),
        }));
    };

    const updateTapEarnRate = (amount) => {
        setUser((prevUser) => ({
            ...prevUser,
            tapEarnRate: Math.min(10, prevUser.tapEarnRate + amount),
        }));
    };

    const decrementContainerCount = () => {
        setUser((prevUser) => ({
            ...prevUser,
            containers: Math.max(prevUser.containers - 1, 0),
        }));
    };

    const updateContainers = (count) => {
        setUser((prevUser) => ({
            ...prevUser,
            containers: prevUser.containers + count,
        }));
    };

    const updateInvitedUsers = (count) => {
        setUser((prevUser) => ({
            ...prevUser,
            invitedUsers: prevUser.invitedUsers + count,
        }));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            regenerateEnergy();
        }, user.energyRegenRate * 1000);
        return () => clearInterval(interval);
    }, [user.energyRegenRate]);

    return (
        <UserContext.Provider
            value={{
                user,
                isAuthenticated,
                loginUser,
                handleTap,
                regenerateEnergy,
                updateStarBalance,
                updateRocketTicket,
                updateSGXBalance,
                updateEnergyRegenRate,
                updateTapEarnRate,
                decrementContainerCount,
                updateInvitedUsers,
                updateUser,
                updateContainers,
                resetUser,
                inviteUser,
                getUsername,
                updateTasks,
                addUsedCode,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

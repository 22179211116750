import React, { useContext, useEffect, useState } from 'react';
import { FaArrowUp, FaBolt, FaHome, FaGift, FaTasks, FaBox, FaWallet, FaCoins, FaTicketAlt } from 'react-icons/fa';
import { FaUsers } from "react-icons/fa";
import RefferalsSection from './RefferalsSection';
import TasksSection from './TasksSection';
import ContainersSection from './ContainersSection';
import Bet from './Bet';
import { UserContext } from '../context/UserContext';
import { AiFillHome } from "react-icons/ai";
import { HiGift } from "react-icons/hi";
import { RiCoinFill } from "react-icons/ri";
import { BsFillInboxFill } from "react-icons/bs";
import { PiWalletFill } from "react-icons/pi";
import { RiRocketFill } from "react-icons/ri";






import './App.css';

const ranks = [
    { name: 'Rank 1', requiredTaps: 3000, image: './rank1.png', energyLimit: 500 },
    { name: 'Rank 2', requiredTaps: 10000, image: './rank2.png', energyLimit: 700 },
    { name: 'Rank 3', requiredTaps: 30000, image: './rank3.png', energyLimit: 900 },
    { name: 'Rank 4', requiredTaps: 50000, image: './rank4.png', energyLimit: 1100 },
    { name: 'Rank 5', requiredTaps: 70000, image: './rank5.png', energyLimit: 1300 },
    { name: 'Rank 6', requiredTaps: 90000, image: './rank6.png', energyLimit: 1500 },
    { name: 'Rank 7', requiredTaps: 150000, image: './rank7.png', energyLimit: 1700 },
    { name: 'Rank 8', requiredTaps: 200000, image: './rank8.png', energyLimit: 1800 },
    { name: 'Rank 9', requiredTaps: 250000, image: './rank9.png', energyLimit: 1900 },
    { name: 'Rank 10', requiredTaps: 500000, image: './rank10.png', energyLimit: 2000 },
];



const GameSection = () => {
    const { user, handleTap, regenerateEnergy, updateStarBalance } = useContext(UserContext);
    const [activeSection, setActiveSection] = useState('home');
    const [showRankUpModal, setShowRankUpModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [tapText, setTapText] = useState([]); // Text array for displaying tap values

    useEffect(() => {
        const interval = setInterval(() => {
            regenerateEnergy();
        }, user.energyRegenRate * 1000);

        return () => clearInterval(interval);
    }, [user.energyRegenRate]);

    // Detectăm rank-up
    useEffect(() => {
        if (user.rankIndex > user.prevRankIndex) { // Afișăm modalul doar la rank-up
            setShowSpinner(true);
            setTimeout(() => {
                setShowSpinner(false);
                setShowRankUpModal(true);
            }, 2000);
        }
    }, [user.rankIndex, user.prevRankIndex]);

    const xpProgressWidth = user.rankIndex < ranks.length - 1
        ? `${(user.xp / ranks[user.rankIndex].requiredTaps) * 100}%`
        : '100%';

    const xpDisplay = user.rankIndex < ranks.length - 1
        ? `${user.xp.toLocaleString()} / ${ranks[user.rankIndex].requiredTaps.toLocaleString()} `
        : 'Max Rank ∞';

    const tapEarnRateDisplay = user.tapEarnRate >= 10 ? 'MAX' : user.tapEarnRate.toLocaleString();
    const energyRegenRateDisplay = user.energyRegenRate <= 0.1 ? 'MAX' : `${user.energyRegenRate.toLocaleString()}s`;

    // Funcția pentru tap, actualizată să folosească updateStarBalance
    const handleTapWithText = (e) => {
        if (user.energy > 0) {
            handleTap(); // Trigger the tap action from context

            // Apelăm updateStarBalance pentru a adăuga punctele de Star

            const newTapText = {
                id: Date.now(),
                x: e.clientX,
                y: e.clientY,
                value: user.tapEarnRate,
            };
            setTapText((prev) => [...prev, newTapText]);
            setTimeout(() => {
                setTapText((prev) => prev.filter((text) => text.id !== newTapText.id));
            }, 1000); // Textul dispare după 1 secundă
        }
    };

    const renderSection = () => {
        switch (activeSection) {
            case 'refferals':
                return <RefferalsSection />;
            case 'tasks':
                return <TasksSection />;
            case 'bet':
                return <Bet />;
            case 'containers':
                return <ContainersSection />;
            default:
                return (
                    <div className='game-display'>
                        <div className="game-xp">
                            <div className="rank-image-container">
                                <img src={ranks[user.rankIndex].image} alt="Rank" className="rank-image" />
                            </div>
                            <div className="xp-bar-container">
                                <div className="xp-bar">
                                    <div className="xp-progress" style={{ width: xpProgressWidth }}>
                                        <p className="game-xp-value">{xpDisplay}</p>
                                        <p className='game-rank-value game-xp-value'>Rank {user.rankIndex + 1}</p>
                                    </div>
                                </div>
                                <div className="energy-info">
                                    <FaBolt className="energy-icon" />
                                    <p className="energy-value">{user.energy}/{ranks[user.rankIndex].energyLimit}</p>
                                </div>
                            </div>
                        </div>

                        <div className="game-stats">
                            <div className="stat-box">
                                <p className="stat-label"><FaCoins />Earn Rate: </p>
                                <p className="stat-value">{tapEarnRateDisplay} </p>
                            </div>
                            <div className="stat-box">
                                <p className="stat-label"><FaBolt />Regen Time:</p>
                                <p className="stat-value">{energyRegenRateDisplay} </p>
                            </div>
                            <div className="stat-box">
                                <p className="stat-label"><BsFillInboxFill />Cases:</p>
                                <p className="stat-value">{user.containers}</p>
                            </div>
                            <div className="stat-box">
                                <p className="stat-label"><FaUsers />Invited Users:</p>
                                <p className="stat-value">{user.invitedUsers}</p>
                            </div>
                            <div className="stat-box">
                                <p className="stat-label"><FaTicketAlt />Tickets:</p>
                                <p className="stat-value">{user.rocketTicket}</p>
                            </div>
                            <div className="stat-box">
                                <p className="stat-label"><RiCoinFill />Balance:</p>
                                <p className="stat-value">{user.starBalance.toLocaleString()} $STAR</p>
                            </div>
                        </div>

                        <div className="game-tap-area" onClick={handleTapWithText}>
                            <div className="game-tap-button"></div>
                            {tapText.map((text) => (
                                <span
                                    key={text.id}
                                    className="tap-text"
                                    style={{
                                        position: 'absolute',
                                        top: text.y,
                                        left: text.x,
                                        fontWeight: 'bold',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {text.value} <RiCoinFill />
                                </span>
                            ))}
                        </div>
                        <div className="energy-info-mobile">
                          <div className='energy-zone'>
                            <FaBolt className="energy-icon" />
                            <p className="energy-value">{user.energy}/{ranks[user.rankIndex].energyLimit}</p>
                          </div>
                          <div className='wallet-zone'>
                          <a
            href="/wallet"
            className={` ${activeSection === 'wallet' ? 'current-page' : ''}`}
          >
            <PiWalletFill style={{ marginRight: '0px' }} />
          </a>
                           </div>
                    
                        </div>
                    </div>
                );
        }
    };

    return (
        <section className="game-section">

            
            {renderSection()}

            {/* Overlay complet care rămâne activ și pe durata modalului */}
            {(showSpinner || showRankUpModal) && (
                <div className="rankup-overlay">
                    {showSpinner && (
                        <div className="loader">
                            <label>Advancing rank...</label>
                            <div className="loading"></div>
                        </div>
                    )}
                </div>
            )}

            {/* Modalul pentru rank-up */}
            {showRankUpModal && (
                <div className="rankup-modal">
                    <div className="rankup-modal-content">
                        <h2>Congratulations!</h2>
                        <p>You have ranked up to <strong>{ranks[user.rankIndex].name}</strong>!</p>
                        <p>You have won a <strong>Case</strong>, and your new energy limit is <strong>{ranks[user.rankIndex].energyLimit}</strong>.</p>
                        <img src={ranks[user.rankIndex].image} alt={`Rank ${ranks[user.rankIndex].name}`} className="rankup-image" />
                        <button onClick={() => setShowRankUpModal(false)}>Close</button>
                    </div>
                </div>
            )}

            <div className="game-actions-menu">
          
          <div
            className={`menu-item ${activeSection === 'refferals' ? 'current-page' : ''}`}
            onClick={() => setActiveSection('refferals')}
          >
            <HiGift style={{ marginRight: '0px' }} className="menu-icon" />
            <p className="menu-label">Reward</p>
          </div>
          <div
            className={`menu-item ${activeSection === 'tasks' ? 'current-page' : ''}`}
            onClick={() => setActiveSection('tasks')}
          >
            <RiCoinFill style={{ marginRight: '0px' }} className="menu-icon" />
            <p className="menu-label">Earn</p>
          </div>
          <div
            className={`menu-item ${activeSection === 'home' ? 'current-page' : ''}`}
            onClick={() => setActiveSection('home')}
          >
            <AiFillHome style={{ marginRight: '0px' }} className="menu-icon" />
            <p className="menu-label">Home</p>
          </div>
          <div
            className={`menu-item ${activeSection === 'containers' ? 'current-page' : ''}`}
            onClick={() => setActiveSection('containers')}
          >
            <BsFillInboxFill style={{ marginRight: '0px' }} className="menu-icon" />
            <p className="menu-label">Cases</p>
          </div>
          <div
            className={`menu-item ${activeSection === 'bet' ? 'current-page' : ''}`}
            onClick={() => setActiveSection('bet')}
          >
            <RiRocketFill style={{ marginRight: '0px' }} className="menu-icon" />
            <p className="menu-label">Rocket</p>
          </div>
          
        </div>
        </section>
    );
};

export default GameSection;


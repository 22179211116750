import React, { useState, useContext, useRef } from 'react';
import { UserContext } from '../context/UserContext';
import { FaUsers } from 'react-icons/fa';
import { RiCoinFill } from 'react-icons/ri';
import { BsFillInboxFill } from 'react-icons/bs';
import { FaInfoCircle, FaCheckCircle, FaTicketAlt } from "react-icons/fa";
import './App.css';
import { IoMdRemove } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";

const MAX_BET = 10000; // Limita maximă de bet

const Bet = () => {
  const { user, updateUser, updateStarBalance } = useContext(UserContext);
  const [currentXValue, setCurrentXValue] = useState(0.00);
  const [betAmount, setBetAmount] = useState(1000);
  const [isGameActive, setIsGameActive] = useState(false);
  const [profit, setProfit] = useState(0);
  const [fixedProfit, setFixedProfit] = useState(0); // Suma de profit fixată la retragere
  const [fixedXValue, setFixedXValue] = useState(0); // Suma de X fixată la retragere
  const [isWithdrawn, setIsWithdrawn] = useState(false); // Stare pentru retragere
  const [gameInterval, setGameInterval] = useState(null);
  const [isRocketShaking, setIsRocketShaking] = useState(false);
  const [isRocketIgnited, setIsRocketIgnited] = useState(false);
  const [backgroundPositionY, setBackgroundPositionY] = useState('100%');
  const [countdown, setCountdown] = useState(3);
  const [showCountdownModal, setShowCountdownModal] = useState(false);
  const [hasLaunched, setHasLaunched] = useState(false);
  const [isRocketFalling, setIsRocketFalling] = useState(false);
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [showWithdrawMessage, setShowWithdrawMessage] = useState(false); // Mesaj retragere
  const [showNoTicketsMessage, setShowNoTicketsMessage] = useState(false); // Mesaj pentru lipsa biletelor
  const [showNoBalanceMessage, setShowNoBalanceMessage] = useState(false); // Mesaj pentru balanță insuficientă
  const [showMaxBetMessage, setShowMaxBetMessage] = useState(false); // Mesaj pentru limita maximă de bet
  const [isGameFinished, setIsGameFinished] = useState(false); // Stare pentru a verifica dacă jocul s-a terminat
  const [isRocketVisible, setIsRocketVisible] = useState(true); // Racheta e vizibilă la început


  // Sunete
  const countdownAudio = useRef(null); // Sunet pentru countdown
  const propulsionAudio = useRef(null); // Sunet pentru propulsie
  const crashAudio = useRef(null); // Sunet pentru prăbușire

  const backgroundSpeedFactor = 1.0;

  // Funcție pentru generarea unui x random pentru explozie
  const generateExplosionValue = () => {
    const maxExplosion = 10;
    const minExplosion = 1.5;
    
    const randomFactor = Math.pow(Math.random(), 2); // Valorile mari sunt mai probabile
    return minExplosion + randomFactor * (maxExplosion - minExplosion);
  };

  const startGame = () => {
    if (user.rocketTicket < 1) {
      showTemporaryMessage(setShowNoTicketsMessage); // Mesaj pentru lipsa biletelor
      return;
    }
  
    if (betAmount > MAX_BET) {
      showTemporaryMessage(setShowMaxBetMessage); // Mesaj pentru limita maximă de bet
      return;
    }
  
    if (user.starBalance < betAmount) {
      showTemporaryMessage(setShowNoBalanceMessage); // Mesaj pentru balanță insuficientă
      return;
    }
  
    // Actualizează utilizatorul cu noul balance și scăderea de bilete
    updateUser({
      rocketTicket: user.rocketTicket - 1,
    });
  
    // Redă sunetul pentru countdown imediat ce jocul începe
    countdownAudio.current.play();
  
    setShowCountdownModal(true);
  
    let countdownValue = 3;
    setCountdown(countdownValue);
  
    // Începe countdown-ul
    const countdownInterval = setInterval(() => {
      countdownValue -= 1;
      setCountdown(countdownValue);
  
      if (countdownValue <= 0) {
        clearInterval(countdownInterval);
        setShowCountdownModal(false);
        startRocketAnimation();
      }
    }, 1000);
  };
  

  const startRocketAnimation = () => {
    setIsGameActive(true);
    setIsWithdrawn(false); // Resetăm retragerea
    setProfit(0);
    setFixedProfit(0); // Resetăm profitul fix
    setFixedXValue(0); // Resetăm X-ul fix
    setCurrentXValue(0);
    setBackgroundPositionY('100%');
    setHasLaunched(false);
    setIsRocketFalling(false); 
  
    const explosionValue = generateExplosionValue(); // Generăm valoarea de explozie
    let xValue = 0.00;
    let increment = 0.003; 
  
    setIsRocketShaking(true);
    setIsRocketIgnited(true);

    // Redăm sunetul de propulsie și setăm pe repeat
    propulsionAudio.current.play();
    propulsionAudio.current.loop = true; // Setăm sunetul de propulsie pe repeat

    setTimeout(() => {
      setIsRocketShaking(false);
      setHasLaunched(true); 
  
      const interval = setInterval(() => {
        xValue += increment;

        if (xValue >= explosionValue) {
          clearInterval(interval);
          initiateRocketFall(); // Inițiem prăbușirea rachetei
        } else {
          setCurrentXValue(parseFloat(xValue.toFixed(2)));
          const adjustedBackgroundPosition = `${100 - (xValue / 10) * 100}%`;
          setBackgroundPositionY(adjustedBackgroundPosition);

          if (!isWithdrawn) { // Profitul se oprește doar dacă nu s-a retras deja
            setProfit(betAmount * (xValue + 1));
          }

          increment += xValue / 5000;
        }
      }, 100);
  
      setGameInterval(interval);
    }, 1000);
  };

  const initiateRocketFall = () => {
    setIsRocketFalling(true);
    setIsGameActive(false);
    setIsGameFinished(true); 
  
    // Oprim sunetul de propulsie și redăm sunetul de prăbușire
    propulsionAudio.current.pause();
    crashAudio.current.play();
  
    // Lăsăm racheta vizibilă pe durata prăbușirii
    setTimeout(() => {
      setIsRocketFalling(false); // Animația de prăbușire se termină
      setIsRocketVisible(false); // Ascundem racheta după 2 secunde (animația)
      setShowEndGameModal(true); // Afișăm modalul de sfârșit al jocului
    }, 2000);
  };
  
  

  const resetGame = () => {
    setCurrentXValue(0.00);
    setBetAmount(1000);
    setIsGameActive(false);
    setProfit(0);
    setFixedProfit(0);
    setFixedXValue(0);
    setIsWithdrawn(false);
    setIsRocketShaking(false);
    setIsRocketIgnited(false);
    setBackgroundPositionY('100%');
    setCountdown(3);
    setShowCountdownModal(false);
    setHasLaunched(false);
    setIsRocketFalling(false);
    setShowEndGameModal(false);
    setShowWithdrawMessage(false);
    clearInterval(gameInterval);
    setIsGameFinished(false);
  
    // Facem racheta vizibilă din nou după resetare
    setIsRocketVisible(true); 
  };
  

const showTemporaryMessage = (setMessageFunction) => {
  setMessageFunction(true);
  setTimeout(() => {
    setMessageFunction(false); // Mesajul dispare după 2 secunde
  }, 5000);
};

// Afișăm mesajul de retragere
const showTemporaryWithdrawMessage = () => {
  setShowWithdrawMessage(true);
  setTimeout(() => {
    setShowWithdrawMessage(false); // Mesajul dispare după 2 secunde
  }, 10000);
};

const withdrawProfit = () => {
  if (!isGameActive || isWithdrawn) return;

  const currentProfit = profit; // Fixăm profitul curent
  setFixedProfit(currentProfit); // Setăm profitul fix în momentul retragerii
  setFixedXValue(currentXValue); // Setăm x-ul fix în momentul retragerii
  setIsWithdrawn(true); // Setăm retragerea profitului
  showTemporaryWithdrawMessage(); // Afișăm mesajul de retragere

  updateStarBalance(currentProfit);
  ;

  // Transformăm butonul în "WAITING" și îl dezactivăm până la sfârșitul rundei
  document.getElementById('betButton').classList.add('waiting-button');
};

const decreaseBetAmount = () => {
  setBetAmount((prevAmount) => Math.max(prevAmount - 1000, 1000));
};

const increaseBetAmount = () => {
  setBetAmount((prevAmount) => prevAmount + 1000);
};

// Funcție pentru a valida și corecta valoarea bet-ului
const validateAndCorrectBetAmount = (value) => {
  if (value.startsWith('0')) {
    return parseInt(value, 10); // Elimină zerourile din față
  }
  return value;
};

const handleBetAmountChange = (event) => {
  let value = event.target.value;

  // Permitem ștergerea completă
  if (value === '') {
    setBetAmount(''); // Setăm inputul la gol
    return;
  }

  // Verificăm și corectăm valoarea
  let parsedValue = validateAndCorrectBetAmount(value);

  // Dacă valoarea este validă și mai mare de 0
  if (!isNaN(parsedValue) && parsedValue >= 0) { 
    if (parsedValue > MAX_BET) {
      showTemporaryMessage(setShowMaxBetMessage); // Mesaj pentru suma maximă
    } else {
      setBetAmount(parsedValue); // Setăm valoarea pariului dacă e validă
    }
  }
};

// Dacă utilizatorul părăsește inputul și câmpul e gol, setăm minimul 1000
const handleBetAmountBlur = () => {
  if (betAmount === '' || betAmount < 1000) {
    setBetAmount(1000);
  }
};

const handleButtonClick = () => {
  if (isGameActive && !isWithdrawn) {
    withdrawProfit(); // Retragem profitul și continuăm jocul
  } else if (!isGameActive) {
    startGame(); // Începem o nouă rundă
  }
};

return (
  <section className='game-display-2'>
    {showNoTicketsMessage && (
      <div className={`promo-message ${showNoTicketsMessage ? 'show' : 'hide'}`} style={{ color: 'white', fontWeight: '500', background: 'linear-gradient(109.6deg, rgb(20, 30, 48) 11.2%, rgb(36, 59, 85) 91.1%)' }}>
        <FaInfoCircle style={{ verticalAlign: 'middle' }}/>
        Not enough rocket tickets!
      </div>
    )}

    {showNoBalanceMessage && (
      <div className={`promo-message ${showNoBalanceMessage ? 'show' : 'hide'}`} style={{ color: 'white', fontWeight: '500', background: 'linear-gradient(109.6deg, rgb(20, 30, 48) 11.2%, rgb(36, 59, 85) 91.1%)' }}>
        <FaInfoCircle style={{ verticalAlign: 'middle' }}/>
        Not enough balance to place bet!
      </div>
    )}

    {showMaxBetMessage && (
      <div className={`promo-message ${showMaxBetMessage ? 'show' : 'hide'}`} style={{ color: 'white', fontWeight: '500', background: 'linear-gradient(109.6deg, rgb(20, 30, 48) 11.2%, rgb(36, 59, 85) 91.1%)' }}>
        <FaInfoCircle style={{ verticalAlign: 'middle' }}/>
        The maximum bet amount is 10,000 $STAR!
      </div>
    )}

    {showWithdrawMessage && (
      <div className={`promo-message ${showWithdrawMessage ? 'show' : 'hide'}`} style={{ color: 'white', fontWeight: '500', background: 'radial-gradient(862px at 6% 18%, rgb(21, 219, 149) 9.4%, rgb(26, 35, 160) 83.6%)' }}>
        <FaCheckCircle style={{ verticalAlign: 'middle' }}/>
        Profit Withdrawn: {fixedProfit.toFixed(2)} $STAR at x{(fixedXValue + 1).toFixed(2)}
      </div>
    )}

    <div className="rocket-game-stats">
      <div className="stat-box" style={{ justifyContent: 'end' }}>
        <p className="stat-label"><RiCoinFill />Balance:</p>
        <p className="stat-value">{user.starBalance.toLocaleString()} $STAR</p>
      </div>
      <div className="stat-box">
        <p className="stat-label"><FaTicketAlt />Tickets:</p>
        <p className="stat-value">{user.rocketTicket}</p>
      </div>
    </div>
  <div
    className={`rocket-display ${isGameActive ? 'rocket-background-scroll' : ''} ${!isGameActive && isRocketIgnited ? 'rocket-background-fall' : ''}`}
    style={{ backgroundPositionY }}
  >
    {/* Elemente audio */}
    <audio ref={countdownAudio} src="countdown.mp3" preload="auto"></audio>
    <audio ref={propulsionAudio} src="propulsion.mp3" preload="auto"></audio>
    <audio ref={crashAudio} src="crash.mp3" preload="auto"></audio>

    {showCountdownModal && (
      <div className="game-countdown-modal">
        <h2>The round starts in:</h2>
        <div className="countdown-number">{countdown}</div>
      </div>
    )}

    

    {showEndGameModal && (
      <div className="rankup-overlay">
      <div className="game-end-modal">
          <h2>The rocket has crashed!</h2>
          <p>Do you have more tickets? Let's play another round!</p>
          <button onClick={resetGame}>Back to game</button>
      </div>
      </div>
    )}

  

    <div className='game-objects'>
      <div className="rocket-container">
        <div className="progress-bar-container">
          <div className="progress-bar">
            <div
              className="progress-bar-fill"
              style={{ height: `${(currentXValue / 10) * 100}%` }}
            />
            <img
              src="rocket.png"
              alt="Mini Rocket"
              className="mini-rocket"
              style={{ bottom: `${(currentXValue / 10) * 100}%` }}
            />
          </div>
        </div>

        {isRocketVisible && (
  <div className="rocket-containers">
    <img
      src="/rocket.png"
      alt="Rocket"
      className={`rocket ${isRocketShaking ? 'rocket-shake' : hasLaunched ? 'rocket-float' : ''} ${isRocketFalling ? 'rocket-fall' : ''}`}
    />
    {isRocketIgnited && (
      <>
        <img
          src="propulsion.gif"
          alt="Rocket Propulsion"
          className={`rocket-thrust ${isRocketFalling ? 'hidden' : ''} ${isRocketShaking ? 'rocket-shake' : ''} ${hasLaunched ? 'rocket-float' : ''}`}
        />
        <div className="rocket-smoke"></div>
      </>
    )}
  </div>
)}



        <div className="current-x-value">x{(currentXValue + 1).toFixed(2)}</div>
      </div>
    </div>

    
  </div>
  <div className="game-controls">
      <div className="bet-amount">
        
        <input 
          type="number"
          value={betAmount}
          onChange={handleBetAmountChange}
          onBlur={handleBetAmountBlur} // Setăm minimul de 1000 dacă e gol
          min="1000"
          className="bet-input"
        />
        
      </div>

      

    </div>
    <button
        id="betButton"
        className={`start-bet ${isWithdrawn || isGameFinished ? 'waiting-button' : ''}`} 
        onClick={handleButtonClick}
        style={{
          // Dacă jocul este activ și nu s-a retras profitul, aplică gradientul portocaliu pentru butonul de "WITHDRAWAL"
          backgroundImage: isGameActive && !isWithdrawn 
            ? 'linear-gradient(to right, orange 70%, rgba(255, 165, 0, 0.5) 100%)'
            // Dacă nu este activ sau jocul e terminat, aplică gradientul dorit
            : 'radial-gradient(circle at 0% 0.5%, rgb(241, 241, 242) 0.1%, rgb(224, 226, 228) 100.2%)',
          backgroundColor: isWithdrawn || isGameFinished ? '#ccc' : '', // Fundalul devine #ccc pentru WAITING
          color: '#0a0a0a', // Text alb pentru toate stările butonului
          pointerEvents: isWithdrawn || isGameFinished ? 'none' : 'auto' // Dezactivăm clicurile
        }}
      >
        {isWithdrawn || isGameFinished 
          ? 'WAITING' 
          : isGameActive 
            ? `WITHDRAWAL ${profit.toFixed(2)} $STAR` 
            : `START THE ROUND `
        }
      </button>
  </section>
);
};

export default Bet;

// src/LogoutButton.jsx
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TbLogout } from 'react-icons/tb';
import { UserContext } from '../context/UserContext'; // Importăm contextul utilizatorului
import pb from '../pocketbase'; // Importăm clientul PocketBase pentru a goli sesiunea de pe server

function LogoutButton() {
  const { resetUser } = useContext(UserContext); // Preluăm funcția de resetare din context
  const navigate = useNavigate();

  const handleSignOut = () => {
    try {
      pb.authStore.clear(); // Golim sesiunea de autentificare din PocketBase
      resetUser(); // Resetăm starea utilizatorului și ștergem sesiunea din localStorage
      navigate('/login'); // Redirecționăm utilizatorul către pagina de login
    } catch (error) {
      console.error('Error during sign out:', error); // În caz de eroare, afișăm în consolă
    }
  };

  return (
    <button onClick={handleSignOut} className="signout-button">
      <TbLogout /> Sign Out
    </button>
  );
}

export default LogoutButton;

import React, { useState } from 'react';
import sgxImage from './SGX.png';
import './App.css';

const SendModal = ({ onClose }) => {
  const [amount, setAmount] = useState('');
  const [gasFee, setGasFee] = useState(0.00005);

  const handleMaxClick = () => {
    // Set the maximum amount based on the available balance
    setAmount('0.0300'); // Example value, replace with dynamic data
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
      <div className="send-modal-content">

        <h2>Withdraw</h2>
        <div className="modal-body">
          <p>Receiver</p>
          <input type="text" placeholder="MultiversX (eGLD) Address" className="input" />
          <p>Amount</p>
          <div className="input-group">
            <input
              type="number"
              placeholder="Minimum 0"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="input amount-input"
            />
            <button onClick={handleMaxClick} className="max-button">MAX</button>
            <div className="token-display">
              <img src={sgxImage} alt="SGX" className="token-icon" />
              <span style={{ fontWeight: 'bold', color: '#ffffffd4' }}>SGX</span>
            </div>
          </div>
          
        </div>
        <div className="modal-footer">
          <button className="send-button">Send SGX</button>
          <button className="cancel-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SendModal;

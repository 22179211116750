// src/context/SGXContext.js
import React, { createContext, useState, useEffect } from 'react';

export const SGXContext = createContext();

export const SGXProvider = ({ children }) => {
    const [generatedSgxBalance, setGeneratedSgxBalance] = useState(0); // Valoare SGX generată constant
    const [rate, setRate] = useState(0); // Rata de generare SGX pe oră

    useEffect(() => {
        const interval = setInterval(() => {
            setGeneratedSgxBalance(prevBalance => prevBalance + rate);
            triggerBalanceAnimation();
        }, 360 * 10); // 1 oră în milisecunde (3600000 ms)

        return () => clearInterval(interval);
    }, [rate]);

    const triggerBalanceAnimation = () => {
        const balanceElement = document.querySelector('.total-SGX-balance');
        if (balanceElement) {
            balanceElement.classList.add('balance-animation');
            setTimeout(() => {
                balanceElement.classList.remove('balance-animation');
            }, 0); // Animația durează 1 secundă
        }
    };

    return (
        <SGXContext.Provider value={{ generatedSgxBalance, setRate }}>
            {children}
        </SGXContext.Provider>
    );
};
